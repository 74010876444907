import React,{useState,useEffect} from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom';
import {Form, Button, FormGroup, ListGroup} from 'react-bootstrap';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import FormContainer from '../../components/FormContainer';
import {toast} from 'react-toastify';
import { useUpdateProductMutation,
  useGetProductDetailsQuery,useUploadProductImageMutation } from '../../slices/productApiSlice';
const ProductEditScreen = () => {
    const {id:productId} = useParams();

    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [image, setImage] = useState('');
    const [brand, setBrand] = useState('');
    const [category, setCategory] = useState('');
    const [countInStock, setCountInStock] = useState(0);
    const [description, setDescription] = useState('');

    const {data,isLoading,error, refetch} = useGetProductDetailsQuery(productId);
    const [updateProduct,{isLoading:loadingUpdate}] = useUpdateProductMutation();
    const [uploadProductImage, {isLoading:loadingUpload}] = useUploadProductImageMutation();
    const navigate = useNavigate();
    useEffect(()=>{
      if(data && data.products){
        setName(data.products.name);
        setPrice(data.products.price);
        setImage(data.products.image);
        setBrand(data.products.brand);
        setCategory(data.products.category);
        setDescription(data.products.description);
        setCountInStock(data.products.countInStock);
      }
    },[data])
    const submitHandler = async (e) =>{
    e.preventDefault();
      const updatedProduct ={
        productId,
        name,
        price,
        image,
        brand,
        category,
        countInStock,
        description
      }
      const result = await updateProduct(updatedProduct);
      if(result.error){
        toast.error(result.error);
      }
      else{
        toast.success('Product updated');
        navigate('/admin/productlist')
      }
    }
    const uploadFileHandler  = async(e) =>{
      const formData = new FormData();
       formData.append('image',e.target.files[0])
       try {
        const res = await uploadProductImage(formData).unwrap();
        setImage(res.image);
        toast.success(res.message);
       } catch (err) {
        toast.error(err?.data?.message || err.error)
       }
    }
  return<>
    <Link to='/admin/productlist' className='btn btn-light my-3'>
     Go Back
    </Link>
    <FormContainer>
        <h1>Edit Product</h1>
        {loadingUpdate && <Loader/>}
        {isLoading ? <Loader/>:error ?<Message variant ='danger'>
        {error.message}</Message>:(
            <Form onSubmit={submitHandler}>
                <Form.Group controlId='name'>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                     type='text'
                     placeholder='Enter name'
                     value={name}
                     onChange={(e) =>setName(e.target.value)}></Form.Control>
                </Form.Group>
                <Form.Group controlId='price'>
                    <Form.Label>Price</Form.Label>
                    <Form.Control
                     type='number'
                     placeholder='Enter price'
                     value={price}
                     onChange={(e) =>setPrice(e.target.value)}></Form.Control>
                </Form.Group>
                <Form.Group controlId = 'image'>
                  <Form.Label>image</Form.Label>
                  <Form.Control 
                    type='text'
                    placeholder='Enter image'
                    value={image}
                    onChange={(e) => setImage}>

                  </Form.Control>
                  <Form.Control type='file' lablel ='Choose file' onChange={uploadFileHandler}>

                  </Form.Control>
                </Form.Group>
              
                <Form.Group controlId='brand'>
                    <Form.Label>Brand</Form.Label>
                    <Form.Control
                     type='text'
                     placeholder='Enter Brand'
                     value={brand}
                     onChange={(e) =>setBrand(e.target.value)}></Form.Control>
                </Form.Group>
                <Form.Group controlId='category'>
                    <Form.Label>category</Form.Label>
                    <Form.Control
                     type='text'
                     placeholder='Enter category'
                     value={category}
                     onChange={(e) =>setCategory(e.target.value)}></Form.Control>
                </Form.Group>

                <Form.Group controlId='countInStock'>
                    <Form.Label>countInStock</Form.Label>
                    <Form.Control
                     type='number'
                     placeholder='Enter count in stock'
                     value={countInStock}
                     onChange={(e) =>setCountInStock(e.target.value)}></Form.Control>
                </Form.Group>
                <Form.Group controlId='description'>
                    <Form.Label>description</Form.Label>
                    <Form.Control
                     type='text'
                     placeholder='Enter description'
                     value={description}
                     onChange={(e) =>setDescription(e.target.value)}></Form.Control>
                </Form.Group>
                <Button
                 type='submit'
                 variant='primary'
                 className='my-2'
                 >Update</Button>
            </Form>
        ) }
    </FormContainer>
  </>
}

export default ProductEditScreen
