import React, { Children, useState } from 'react'
import {Container,Row, Col} from 'react-bootstrap';
function FormContainer({children}) {
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('');
  return (
    

    <Container>
        <Row className='justify-content-md-center'>
            <Col xs ={12} md ={6}>
                {children}
            </Col>
        </Row>
    </Container>
  )
}

export default FormContainer
