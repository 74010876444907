import React from 'react'
import { useParams, Link,useNavigate } from 'react-router-dom'
import {Row,Col,Image,Card,ListGroup,Button,Form} from 'react-bootstrap'
import {useDispatch, useSelector } from 'react-redux';
import {toast} from 'react-toastify';
import Meta from '../components/Meta';
// import products from '../products'
import { useGetProductDetailsQuery, useCreateReviewMutation } from '../slices/productApiSlice';
import { addToCart } from '../slices/cartSlice';
import { useState } from 'react';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Rating from '../components/Rating'
const ProductScreen = () => {
  const {id:productId} = useParams();
  const [qty,setQty] = useState(1);
  const [rating, setRating] = useState(0);
  const [comments,setComment] = useState('');
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addToCartHandler = ()=>{
     dispatch(addToCart({...product,qty}))
     navigate('/cart')
  }
  const {data:product,isLoading, refetch,error} = useGetProductDetailsQuery(productId);
  const [createReview, {isLoading:loadingProductReview}] = useCreateReviewMutation();
  const { userInfo }  = useSelector(state => state.auth);
  const submitHandler = async (e) =>{
    e.preventDefault();
    try {
      console.log(productId,rating,comments);
      const result =await createReview({
        
        productId,
        rating,
        comments,
      }).unwrap();
      console.log(result);
      toast.success('Review submitted');
      refetch();
      setRating(0);
      setComment('')
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  }
  return (
    <>
    <Link className='btn btn-light my-3' to='/'>Go back</Link>
    {isLoading?(<Loader/>):error ?(<Message variant='danger'>{error?.data?.message ||error.error}</Message>):(
      <>
      <Meta title={product.name} />
        <Row>
      <Col md = {5}>
      <Image src={product.image} alt={product.name} />
      </Col>
      <Col md = {4}>
       <ListGroup variant='flush' >
        <ListGroup.Item>
          <h3>{product.name}</h3>
        </ListGroup.Item>
        <ListGroup.Item>
          <Rating value={product.rating} text ={`${product.numReviews} reviews`}/>
          </ListGroup.Item>
          <ListGroup.Item>
           Price:$ {product.price}
          <ListGroup.Item>{product.description}</ListGroup.Item>
        </ListGroup.Item>
       </ListGroup>
      </Col>
      <Col md = {3}>
      <Card>
      <ListGroup variant='flush'>
        <ListGroup.Item>
         <Row>
          <Col>Price:</Col>
          <Col><strong>${product.price}</strong></Col>
         </Row>
        </ListGroup.Item>
        <ListGroup.Item>
         <Row>
          <Col>Status:</Col>
          <Col><strong>${product.countInStock > 0 ? 'In Stack':'Out of stack'}</strong></Col>
         </Row>
        </ListGroup.Item>
        {product.countInStock > 0 && (
          <ListGroup.Item>
            <Row>
              <Col>Quantity</Col>
              <Col>
              <Form.Control
           as='select'
           value ={qty}
          onChange={(e)=>setQty(Number(e.target.value))}>
           {[...Array(product.countInStock).keys()].map((x)=>(
            <option key ={x+1} value ={x+1}>
              {x+1}
            </option>
           ))}
          </Form.Control>
              </Col>
            </Row>
          </ListGroup.Item>
          
        )}
        <ListGroup.Item>
          <Button className='btn-dark btn-block' type='button' disabled ={product.countInStock ===0}
          onClick={addToCartHandler}>Add To Cart</Button>
        </ListGroup.Item>
      </ListGroup>
      </Card>
      </Col>
    </Row>
    <Row className='review'>
      <Col md ={6}>
      <h2>Reviews</h2>
      {product.reviews.length === 0 && <Message>No Reviews</Message>}
      <ListGroup varient='flush'>
        {product.reviews.map(review =>(
          <ListGroup.Item key={review._id}>
            <strong>{review.name}</strong>
            <Rating value={review.rating} />
            <p>{review.createdAt.substring(0,10)}</p>
            <p>{review.comment}</p>
          </ListGroup.Item>

        ))}
        <ListGroup.Item>
        <h2>Write a customer Review</h2>
          {loadingProductReview && <Loader/>}
          {userInfo ? (
            <Form onSubmit={submitHandler}>
              <Form.Group controlId='rating' className='my-2'>
              <Form.Control as ='select'
              value={rating}
              onChange={(e) =>setRating(Number(e.target.value))}>
                <option value=''>Select.....</option>
                <option value='1'>1 - Poor </option>
                <option value='2'>2 - Fair</option>
                <option value='3'>3 - Good</option>
                <option value='4'>4 - Very Good</option>
                <option value='5'>5 - Excellent</option>
              </Form.Control>
              </Form.Group>
              <Form.Group controlId='comment' className='my-2'>
                <Form.Label>Comment</Form.Label>
                <Form.Control as='textarea'
                rows='3'
                value={comments}
                onChange={(e) => setComment(e.target.value)}>
           
                </Form.Control>
              </Form.Group>
              <Button type='submit'variant='primary'disabled = {loadingProductReview}>Submit</Button>
            </Form>
          ):(
            <Message>
              please <Link to='/login'>Sign In</Link> to write a review.
            </Message>
          )}

        </ListGroup.Item>
      </ListGroup>
      </Col>
    </Row>
    </>
      )}
    
    </>
  )
}

export default ProductScreen
