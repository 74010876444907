import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import {PayPalScriptProvider} from '@paypal/react-paypal-js'
import {HelmetProvider} from 'react-helmet-async';
import store from './store';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter,
createRoutesFromElements,
Route,
RouterProvider
} from 'react-router-dom'
import HomeScreen from './screens/HomeScreen';
import ProductScreen from './screens/ProductScreen';
import CartScreen from './screens/CartScreen';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import PrivateRoute from './components/PrivateRoute';
import ShippingScreen from './screens/ShippingScreen';
import PaymentScreen from './screens/PaymentScreen';
import PlaceOrderScreen from './screens/PlaceOrderScreen';
import OrderScreen from './screens/OrderScreen';
import OrderListScreen from './screens/admin/OrderListScreen';
import AdminRoute from './components/AdminRoute';
import ProfileScreen from './screens/ProfileScreen';
import ProductListScreen from './screens/admin/ProductListScreen';
import ProductEditScreen from './screens/admin/ProductEditScreen';
import UserListScreen from './screens/admin/UserListScreen';
import UserEditScreen from './screens/admin/UserEditScreen';
const router =createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element ={<App/>}>
      <Route index ={true} path='/' element ={<HomeScreen/>}/>
      <Route path='/search/:keyword' element ={<HomeScreen/>}/>
      <Route path='/pages/:pageNumber' element ={<HomeScreen/>}/>
      <Route path='/search/:keyword/page/:pageNumber' element ={<HomeScreen/>}/>
      <Route path='/product/:id' element ={<ProductScreen/>}/>
      <Route path='/cart' element ={<CartScreen/>}/>
      <Route path='/login' element ={<LoginScreen/>} />
      <Route path ='/register' element ={<RegisterScreen />}/>
      
      <Route path='' element ={<PrivateRoute />}>
      <Route path='/shipping' element = {<ShippingScreen/>}/>
      <Route path ='/payment' element = {<PaymentScreen />}/>
      <Route path='/placeorder' element ={<PlaceOrderScreen/>}/>
      <Route path = '/order/:id' element ={<OrderScreen/>}/>
      <Route path='/profile' element ={<ProfileScreen />}/>
      </Route>
      <Route path='' element ={<AdminRoute />}>
        <Route path='/admin/orderlist' element = {<OrderListScreen />}/>
        <Route path='/admin/productlist' element = {<ProductListScreen />}/>
        <Route path='/admin/productlist/:pageNumber' element = {<ProductListScreen />}/>
        <Route path= '/admin/product/:id/edit' element ={<ProductEditScreen />}/>
        <Route path ='/admin/userlist' element ={<UserListScreen />}/>
        <Route path ='/admin/user/:id/edit' element ={<UserEditScreen/>}/>
      </Route>
    </Route>
  )
)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <HelmetProvider>
  <Provider store ={store}>
  <PayPalScriptProvider deferLoading ={true}>
   <RouterProvider router ={router}/>
   </PayPalScriptProvider>
   </Provider>
   </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();