import React,{useState,useEffect} from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom';
import {Form, Button, FormGroup, ListGroup} from 'react-bootstrap';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import FormContainer from '../../components/FormContainer';
import {toast} from 'react-toastify';
import { useGetUserDetailsQuery,useUpdateUserMutation } from '../../slices/usersApiSlice';

const UserEditScreen = () => {
    const {id:userId} = useParams();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    

    const {data:user,isLoading,error, refetch} =useGetUserDetailsQuery(userId);
    const [updateUser,{isLoading:loadingUpdate}] = useUpdateUserMutation();
   
    const navigate = useNavigate();
    useEffect(()=>{
      if(user){
        setName(user.name);
        setEmail(user.email);
        setIsAdmin(user.isAdmin);
      }
    },[user])
    const submitHandler = async (e) =>{
    e.preventDefault();
    try {
      
const result =await updateUser({userId,name,email,isAdmin});
console.log(result);
  toast.success('user updated successfully');
  refetch();
    navigate('/admin/userlist')
    } catch (err) {
        toast.error(err?.data?.message || err.error)
    }
    }
    
  return<>
    <Link to='/admin/userlist' className='btn btn-light my-3'>
     Go Back
    </Link>
    <FormContainer>
        <h1>Edit User</h1>
        {loadingUpdate && <Loader/>}
        {isLoading ? <Loader/>:error ?<Message variant ='danger'>
        {error.message}</Message>:(
            <Form onSubmit={submitHandler}>
                <Form.Group controlId='name'>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                     type='text'
                     placeholder='Enter name'
                     value={name}
                     onChange={(e) =>setName(e.target.value)}></Form.Control>
                </Form.Group>
                <Form.Group controlId='email'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                     type='email'
                     placeholder='Enter Email'
                     value={email}
                     onChange={(e) =>setEmail(e.target.value)}></Form.Control>
                </Form.Group>
                <Form.Group controlId='isAdmin' className='my-2'>
                 <Form.Check
                  type='checkbox'
                  label ='isAdmin'
                  checked ={isAdmin}
                  onChange={(e) => setIsAdmin(e.target.checked)}>

                 </Form.Check>
                </Form.Group>
                <Button
                 type='submit'
                 variant='primary'
                 className='my-2'
                 >Update</Button>
            </Form>
        ) }
    </FormContainer>
  </>
}

export default UserEditScreen
